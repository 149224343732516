import { Box, Container } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import HeaderSm from '../../pages/store/header-sm/HeaderSm';
import Rightbar from '../../pages/store/rightbar/Rightbar';
import Sidebar from '../../pages/store/sidebar/Sidebar';
import "./StoreLayout.css";

function StoreLayout(props) {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const listItemClick = (event, index, page) => {
        setSelectedIndex(index);
        navigate(page);
    };
    return (
        <>
            <Box className='v1-store-page'>
                <Box className='store-header-md'>
                    <Header />
                </Box>
                <Box>
                    <HeaderSm />
                </Box>
                <Container className='v1-store-container-wrap'>
                    <Box className='v1-store-container'>
                        <Box className='v1-store-sidemenu'>
                            <Sidebar selectedIndex={selectedIndex} handleListItemClick={listItemClick} />
                        </Box>
                        <Box>
                            <Outlet />
                        </Box>
                        <Box className='v1-store-rightmenu'>
                            <Rightbar />
                        </Box>
                    </Box>
                </Container>
                <Box className='store-footer-md'>
                    <Footer />
                </Box>
            </Box>
        </>
    );
}

export default StoreLayout;