import { Box, Container, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SITE_CONTACT, SITE_MAIL, WHATSAPP_URL } from '../../constants/Site';
import Logo from '../logo/Logo';
import "./Footer.css";
import Iconify from '../../portal/compoments/iconify/Iconify';

function Footer(props) {

    const sayWhatsApp = () => {
        window.open(`${WHATSAPP_URL}?phone=${SITE_CONTACT}&text=Hi`, '_blank');
    }

    return (
        <>
            <Box>
                <Box style={{
                    marginTop: 80,
                    background: '#2c303a',
                    color: '#fff',
                    padding: '30px 20px'
                }}>
                    <Container>
                        <Box className='v1-footer-container'>
                            <Box>
                                <Box>
                                    <Logo color="rgb(255, 255, 255)" />
                                </Box>
                                <Box>
                                    <Typography style={{
                                        fontSize: 12
                                    }}>
                                        Create your online digital store and QR code with TrueMenu, Scan it from anywhere to view the menu items and store details.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className='f-link-wrap'>
                                <Typography className='f-heading'>Quick Links</Typography>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/book-a-demo">Book a Demo</Link>
                                    </li>
                                    <li>
                                        <Link to="/login">Login</Link>
                                    </li>
                                </ul>
                            </Box>
                            <Box className='f-link-wrap'>
                                <Typography className='f-heading'>Get in touch</Typography>
                                <ul>
                                    <li>
                                        <Link>Email Us: {SITE_MAIL}</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms">Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                        <Box className="whatsapp-ico">
                            <IconButton onClick={sayWhatsApp}><Iconify width="80" icon="simple-icons:whatsapp" color="#fff" /></IconButton>
                        </Box>
                    </Container>
                </Box>
                <Box style={{
                    background: '#20232a',
                    color: "#fff",
                    padding: '10px 20px'
                }}>
                    <Container>
                        <Typography style={{
                            fontSize: 14
                        }}> &copy; TrueMenu. 2023. All rights reserved</Typography>
                        {/* <Typography style={{
                            fontSize: 12
                        }}>When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience.</Typography> */}
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default Footer;