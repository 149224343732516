import { Divider, IconButton, Slide, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import ItemList from '../../components/item-list/ItemList';
import Iconify from '../../portal/compoments/iconify/Iconify';
//import "./StoreItems.css";

function StoreItems(props) {

    return (
        <Box>
            <Box className="v1-store-items-page">
                <Box className="v1-store-items-header">
                    <Box className="v1-store-items-page-wrap">
                        <Typography className='h5' gutterBottom>
                            <Link style={{ textDecoration: 'none' }} to="/jj">
                                <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                            </Link>  Thumbs up
                        </Typography>
                    </Box>
                </Box>
                <Divider className='v1-items-divider' />
                <Box>
                    <Box className="v1-store-items-body-wrap">
                        <Typography style={{
                            paddingTop: 20,
                            fontSize: 14
                        }}>20 Items</Typography>
                        <ItemList />
                        <ItemList />
                        <ItemList />
                        <ItemList />
                        <ItemList />
                        <ItemList />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default StoreItems;