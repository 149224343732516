import React, { useEffect } from 'react';
//import "./Store.css";
import {
    IoStar, IoArrowForwardSharp
} from "react-icons/io5";
import { Button, Box, Avatar, Card, ListItemButton, ListItemText, List, Collapse, TextField } from '@mui/material';
import Rating from '@mui/material/Rating';
import Ratings from '../../components/ratings/Ratings';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Review from '../../components/review/Review';
import Iconify from '../../portal/compoments/iconify/Iconify';
import BottomSheet from '../../components/bottomsheet/BottomSheet';
import AppDialog from '../../components/app-dialog/AppDialog';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import Lottie from "lottie-react";
import qrScanAnimation from "./qr-scan-code.json";

function Store(props) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [faqOpen, setFaqOpen] = React.useState(false);
    const [aboutOpen, setAboutopen] = React.useState(false);
    const [addReviewOpen, setAddReviewOpen] = React.useState(false);
    const [isLoginBottomSheet, setIsLoginBottomSheet] = React.useState(false);
    const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);
    const [isLoadingQr, setisLoadingQr] = React.useState(false);
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (isMobile) {
            setisLoadingQr(true);
        } else {
            setisLoadingQr(false);
        }
    }, [])

    const closeBottomSheet = () => {
        document.body.style.overflow = 'unset';
        setIsLoginBottomSheet(false);
    }

    const openLoginDialog = () => {
        if (isMobile) {
            setIsLoginBottomSheet(true);
        } else {
            setLoginDialogOpen(true);
        }
    }
    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClickClose = () => {
        setDialogOpen(false);
        setAboutopen(false);
        setAddReviewOpen(false);
        setLoginDialogOpen(false);
    };

    const handleFaqClick = () => {
        setFaqOpen(!faqOpen);
    };
    const handleAboutClickOpen = () => {
        setAboutopen(true);
    };
    const handleAddReviewClickOpen = () => {
        setAddReviewOpen(true);
    };

    setTimeout(() => {
        setisLoadingQr(false);
    }, 2000);

    const dialogTitle = (
        <>
            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <img src="https://images.pexels.com/photos/808941/pexels-photo-808941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=" />
                </div>
                <div className='reviewDialogName'>
                    <div>Raghav Ayurveda</div>
                    <div><Rating name="read-only" value={2} readOnly size='small' /></div>
                </div>
            </div>
            <div className='reviewStarFilter'>
                <Box className='reviewStarWrap'>
                    <ul>
                        <li className='active'>
                            <Button variant='outlined' className='reStarFilterType'>All</Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>Positive</Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>Critical</Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>5 <IoStar /></Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>4 <IoStar /></Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>3 <IoStar /></Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>2 <IoStar /></Button>
                        </li>
                        <li>
                            <Button variant='outlined' className='reStarFilterType'>1 <IoStar /></Button>
                        </li>
                    </ul>
                </Box>
                {/* <div className='reviewFilterMore'>
                    <div>Most recent</div> <Button><IoFilterOutline /></Button>
                </div> */}
            </div>
        </>
    );

    const dialogAboutTitle = (
        <>
            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <img src="https://images.pexels.com/photos/808941/pexels-photo-808941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=" />
                </div>
                <div className='reviewDialogName'>
                    <div>Raghav Ayurveda</div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 400
                    }}>About store</div>
                </div>
            </div>
        </>
    );
    const dialogAddReviewTitle = (
        <>
            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <img src="https://images.pexels.com/photos/808941/pexels-photo-808941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=" />
                </div>
                <div className='reviewDialogName'>
                    <div>Raghav Ayurveda</div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 400
                    }}>Rate this store</div>
                </div>
            </div>
        </>
    );

    const dialogAddReviewFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <Button size='medium'>Cancel</Button>
                <Button style={{
                    background: 'rgb(0, 171, 85)',
                    color: '#fff',
                    minWidth: 100
                }} size='medium'>Submit</Button>
            </Box>
        </>
    )

    return (

        <>
            <Box className='v1-store-about'>
                <Card className='v1-store-card-w'>
                    <Box className='v1-store-about-container'>
                        <Box className='v1-store-about-head'>
                            <Avatar
                                variant="square"
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                className='v1-store-av'
                            />
                            <Box className='v1-store-name-wrap'>
                                <Typography className='v1-store-name'>
                                    Smana Hotels
                                </Typography>
                                <Box style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    flexWrap: 'wrap'
                                }}>
                                    <Iconify icon={'material-symbols:location-on-outline'} />
                                    <Typography style={{
                                        fontSize: 13
                                    }}>
                                        Kalewadi Phata
                                    </Typography>
                                </Box>
                                <Box className='time-wrap'>
                                    <Box>
                                        <span className='time-txt'>OPEN:</span> 06:00am - 10:00pm(Today)
                                    </Box>
                                    <Iconify icon={'ic:baseline-arrow-drop-down'} style={{
                                        height: 30,
                                        width: 30
                                    }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className='v1-store-about-actions'>
                            <ul>
                                <li style={{
                                    minWidth: 85
                                }}>
                                    <Box style={{
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}>
                                        <Box>4.2</Box>
                                        <Iconify icon={'ic:outline-star'} />
                                    </Box>
                                    <Box className='subText'>9.34M reviews</Box>
                                </li>
                                <li>
                                    <Box>100K</Box>
                                    <Box className='subText'>Recommended</Box>
                                </li>
                                <li>
                                    <a href='#'>
                                        <Box><Iconify icon={'material-symbols:call'} /></Box>
                                        <Box className='subText'>Call</Box>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <Box><Iconify icon={'ic:round-whatsapp'} /></Box>
                                        <Box className='subText'>WhatsApp</Box>
                                    </a>
                                </li>
                                <li style={{
                                    minWidth: 60
                                }}>
                                    <a href='#'>
                                        <Box><Iconify icon={'material-symbols:map-outline'} /></Box>
                                        <Box className='subText'>View map</Box>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <Box><Iconify icon={'fa:external-link'} /></Box>
                                        <Box className='subText'>Website</Box>
                                    </a>
                                </li>
                            </ul>
                        </Box>
                        <Box className='v1-store-about-ac-btns'>
                            <Box>
                                <Button onClick={openLoginDialog} variant="contained" style={{
                                    backgroundColor: 'rgb(0, 171, 85)'
                                }}>
                                    Recommend
                                </Button>
                            </Box>
                            <Box>
                                <Button style={{
                                }} onClick={handleAddReviewClickOpen}>
                                    Write a review
                                </Button>
                            </Box>
                        </Box>
                        <Box className='divider'></Box>
                        <Box className='sAbout'>
                            <Box className='sTitle'>
                                <Box>Location</Box>
                            </Box>
                            <Box className='sBody sAddress'>
                                <Typography className='SBodyText'>Genesh Nagar, near ganesh mandir, Dange chowk, Thergaon, Pune, Maharastra 411033</Typography>
                                <Typography style={{
                                    width: 170
                                }}><Button>Get Direction</Button></Typography>
                            </Box>
                        </Box>
                        <Box className='divider'></Box>
                        <Box className='sAbout'>
                            <Box className='sTitle'>
                                <Box>About</Box>
                                <Box>
                                    <IconButton onClick={handleAboutClickOpen}><IoArrowForwardSharp size={20} color="#000" /></IconButton>
                                </Box>
                            </Box>
                            <Box className='sBody'>
                                <Typography className='SBodyText'> With Smart Connector by TapClicks, your data options are infinite for importing offline and third party data. If you can measure it, TapClicks can report it, and you can aggregate, design, and manage it as you need. A true custom services builder, automated uploads with Google Drive, Dropbox or FTP. Enhance your Google Sheets with TapClicks Smart Connector and master your data!</Typography>
                            </Box>
                        </Box>
                        <Box className='divider'></Box>
                        <Box className='sAbout'>
                            <Box className='sReview'>
                                <Box>Rate this store</Box>
                                <Box className='sReviewSub'>Tell others what you think.</Box>
                            </Box>
                            <Box className='sBody'>
                                <Box className='sWriteReview'>
                                    <Box>
                                        <Rating onClick={handleAddReviewClickOpen} size="large" />
                                    </Box>
                                    <Box>
                                        <Button onClick={handleAddReviewClickOpen}> Write Review</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='sRatingDisplay'>
                            <Box className='sTitle'>
                                <Box>Ratings and reviews</Box>
                                <Box>
                                    <IconButton onClick={handleDialogClickOpen}><IoArrowForwardSharp size={20} color="#000" /></IconButton>
                                </Box>
                            </Box>
                            <Box className='sBody'>
                                <Ratings />
                            </Box>
                        </Box>
                        <Box className='sUserReviews'>
                            <Review />
                            <Review />
                            <Review />
                            <Box className='sReviewSeeAll'><Button onClick={handleDialogClickOpen} size='small'>See All Reviews</Button></Box>
                        </Box>
                        <Box className='divider'></Box>
                        <Box className='sAbout'>
                            <Box className='sTitle'>
                                <Box>Frequently Asked Question</Box>
                            </Box>
                            <Box style={{
                                margin: '0px 20px'
                            }}>
                                <List>
                                    <ListItemButton onClick={handleFaqClick} style={{
                                        backgroundColor: 'rgba(0, 171, 85, 0.08)',
                                        marginTop: 12,
                                        borderRadius: 6
                                    }}>
                                        <ListItemText primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 600
                                        }} primary="Can I join economics classes to prepare for my ISC exams?" />
                                        {faqOpen ? <Iconify icon={'bi:chevron-down'} /> : <Iconify icon={'bi:chevron-right'} />}
                                    </ListItemButton>
                                    <Collapse in={faqOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 2 }}>
                                                <ListItemText primaryTypographyProps={{
                                                    fontSize: 15
                                                }} primary="Most of the coaching classes give a trial class. However, you must contact Ambiteous Commerce Classes if you are interested in the trial class." />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                    <ListItemButton onClick={handleFaqClick} style={{
                                        backgroundColor: 'rgba(0, 171, 85, 0.08)',
                                        marginTop: 12,
                                        borderRadius: 6
                                    }}>
                                        <ListItemText primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 600
                                        }} primary="Do Ambiteous Commerce Classes in Wanowrie, Pune give a trial class?" />
                                        {faqOpen ? <Iconify icon={'bi:chevron-down'} /> : <Iconify icon={'bi:chevron-right'} />}
                                    </ListItemButton>
                                    <Collapse in={faqOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 2 }}>
                                                <ListItemText primaryTypographyProps={{
                                                    fontSize: 15
                                                }} primary="As most institutes offer weekend and weekday batches, you can contact Ambiteous Commerce Classes to know more about weekend batches." />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>
                                <Box style={{
                                    marginBottom: 20
                                }}></Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>

            {isLoginBottomSheet &&
                <BottomSheet isShow={isLoginBottomSheet} closeBottomSheet={closeBottomSheet} >
                    <Box className='login-content'>
                        <Typography>Login</Typography>
                        <Box>
                            <TextField inputProps={{
                                style: { fontSize: 16, fontWeight: 500 }
                            }} fullWidth autoFocus label="Email or mobile number" variant="outlined" />
                        </Box>
                        <Box>
                            <Button size='large' variant="contained" style={{
                                backgroundColor: 'rgb(0, 171, 85)',
                                width: '100%'
                            }}>
                                Log in
                            </Button>
                        </Box>
                        <Box>
                            <Typography className='l-policy-section'>
                                By continuing, you agree to our <Link to="/terms" style={{
                                    textDecoration: 'underline'
                                }}>Terms of Use</Link> and <Link to="/privacy" style={{
                                    textDecoration: 'underline'
                                }}>Privacy Policy</Link>.
                            </Typography>
                        </Box>
                    </Box>
                </BottomSheet>
            }


            {dialogOpen &&
                <AppDialog isOpen={dialogOpen} id={'store_review_sm'} handleClickClose={handleDialogClickClose} title={dialogTitle}>
                    <Review />
                    <Review />
                    <Review />
                </AppDialog>
            }
            {aboutOpen &&
                <AppDialog isOpen={aboutOpen} id={'store_about_sm'} handleClickClose={handleDialogClickClose} title={dialogAboutTitle}>
                    <Box style={{
                        padding: 15
                    }}>
                        <Typography style={{
                            fontSize: 15,
                        }}>With Smart Connector by TapClicks, your data options are infinite for importing offline and third party data. If you can measure it, TapClicks can report it, and you can aggregate, design, and manage it as you need. A true custom services builder, automated uploads with Google Drive, Dropbox or FTP. Enhance your Google Sheets with TapClicks Smart Connector and master your data!</Typography>
                        <Typography style={{
                            fontSize: 15,
                        }}>With Smart Connector by TapClicks, your data options are infinite for importing offline and third party data. If you can measure it, TapClicks can report it, and you can aggregate, design, and manage it as you need. A true custom services builder, automated uploads with Google Drive, Dropbox or FTP. Enhance your Google Sheets with TapClicks Smart Connector and master your data!</Typography>
                    </Box>
                </AppDialog>
            }
            {addReviewOpen &&
                <AppDialog isOpen={addReviewOpen} id={'store_add_review_sm'} handleClickClose={handleDialogClickClose} title={dialogAddReviewTitle}
                    footer={dialogAddReviewFooter}
                >
                    <Box style={{
                        padding: 15
                    }}>
                        <Box>
                            <Typography>Rating & Review</Typography>
                            <Box className="store-rating-dialog-wrap"
                            >
                                <Rating size="large" />
                            </Box>
                        </Box>
                        <Box style={{
                            marginTop: 15
                        }}>
                            <TextField fullWidth multiline rows={2} label="Describe your experience (optional)" />
                        </Box>
                    </Box>
                </AppDialog>
            }

            {loginDialogOpen &&
                <AppDialog isOpen={loginDialogOpen} id={'store_login_sm'} handleClickClose={handleDialogClickClose} title={<>Login</>}
                    footer={dialogAddReviewFooter}
                >
                </AppDialog>
            }

            {isLoadingQr &&
                <AppDialog isOpen={isLoadingQr} id={'store_qr_animation_sm'} footer={<><Box style={{
                    width: '100%',
                    textAlign: 'center',
                    color: '#000'
                }}>Powered by TrueMenu</Box></>}>
                    <Box style={{
                        maxWidth: 320,
                        margin: '0 auto'
                    }}>
                        <Lottie animationData={qrScanAnimation} loop={true} />
                        <Typography style={{
                            textAlign: 'center',
                            color: '#000',
                            fontWeight: 700
                        }}>Avantar Spa and Services</Typography>
                    </Box>
                </AppDialog>
            }

        </>
    );
}

export default Store;